<template>
    <v-row justify="center">
        <v-col cols="4">
            <v-btn :color="color" class="white--text" :block="block" :disabled="disabled" @click="handleClick">
                <slot></slot>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: true,
        },
        block: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClick() {
            this.$emit('click');
        },
    },
};
</script>
